import { useEffect } from 'react';

import { logger } from '@/logger';
import { useNavbarStatus, useNavbarStore } from '@/modules/navbar/states';
import { INTERCOM_WIDGET_ALLOWLIST } from '@/modules/routing/routes.config.js';
import { getRouteFromPathname } from '@/modules/routing/routes.js';
import { getPathname } from '@/modules/routing/utils/location.js';
import { useServices } from '@/Services';

import { isMobileViewport } from './useIsMobileViewport.js';

// TODO: Refactor this.
// Ideally we need a way to track all opened modals and panel.
// Probably use a zustand store and keep a stack of opened modals, but this
// requires changing a loot a code and we may need a new dialog module which some container.
export function useAutohideIntercomBubble() {
  const { intercomService } = useServices();

  const navbarStore = useNavbarStore();
  const navbarStatus = useNavbarStatus(navbarStore);

  useEffect(() => {
    // If the navbar is opened, always hide the bubble.
    if ((navbarStatus === 'open' || navbarStatus === 'opening') && isMobileViewport()) {
      intercomService?.setBubbleDisplayed(false);
      return;
    }

    const dialogRoot = document.getElementById('dialog');
    const panelRoot = document.getElementById('panel');
    if (dialogRoot == null) {
      logger.error('DIALOG_ROOT_NOT_FOUND');
      return;
    }
    if (panelRoot == null) {
      logger.error('PANEL_ROOT_NOT_FOUND');
      return;
    }

    const updateBubbleVisibility = () => {
      const currentRoute = getRouteFromPathname(getPathname());
      const show =
        !dialogRoot.hasChildNodes() &&
        !panelRoot.hasChildNodes() &&
        currentRoute != null &&
        INTERCOM_WIDGET_ALLOWLIST.includes(currentRoute);
      intercomService?.setBubbleDisplayed(show);
    };

    updateBubbleVisibility();

    const observer = new MutationObserver(() => {
      updateBubbleVisibility();
    });

    observer.observe(dialogRoot, { childList: true });
    observer.observe(panelRoot, { childList: true });
    return () => {
      observer.disconnect();
    };
  }, [intercomService, navbarStatus]);
}
